import React from 'react'

function Line() {
  return (
    <div><svg width="17" height="3" viewBox="0 0 17 3" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.5 0.265625V2.1875H0.5V0.265625H16.5Z" fill="white"/>
    </svg>
    </div>
  )
}

export default Line