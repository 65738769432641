import React from 'react'

function Flag2() {
  return (
    <div> <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19 18.981H5C3.895 18.981 3 18.086 3 16.981V7.01904C3 5.91404 3.895 5.01904 5 5.01904H19C20.105 5.01904 21 5.91404 21 7.01904V16.982C21 18.086 20.105 18.981 19 18.981V18.981Z"
      stroke="#323232"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17 9L12 12L7 9"
      stroke="#323232"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</div>
  )
}

export default Flag2