import React from 'react'

function CodedEstate() {
  return (
    <div>
  <svg
                    className="w-full absolute top-4 left-0 widthAnimation"
                    width="352"
                    height="32"
                    viewBox="0 0 352 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      width="352"
                      height="32"
                      fill="url(#paint0_linear_963_79931)"
                      fill-opacity="0.12"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_963_79931"
                        x1="101.689"
                        y1="16"
                        x2="107.865"
                        y2="-22.8301"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop offset="2.29095e-07" stop-color="#6317F7" />
                        <stop offset="1" stop-color="#18FFFF" />
                      </linearGradient>
                    </defs>
                  </svg>

    </div>
  )
}

export default CodedEstate