import React from 'react'

function Coded() {
  return (
    <div><svg width="116" height="18" viewBox="0 0 116 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_582_36937)">
    <path d="M28.2097 7.38865C27.3153 7.38865 26.6383 6.67092 26.6383 5.72596C26.6383 4.7973 27.3153 4.0877 28.2097 4.0877C28.7086 4.0877 29.2564 4.3697 29.6275 4.82178L30.4569 3.83721C29.8927 3.25578 29.0226 2.88525 28.1852 2.88525C26.4698 2.88525 25.1729 4.11219 25.1729 5.74226C25.1729 7.39681 26.4372 8.64705 28.1376 8.64705C28.9679 8.64705 29.8613 8.23575 30.4662 7.61354L29.6288 6.72566C29.2333 7.13 28.6853 7.38865 28.2097 7.38865Z" fill="white"/>
    <path d="M33.1138 8.63161C34.5318 8.63161 35.4835 7.74365 35.4835 6.40352C35.4835 5.06342 34.5331 4.18359 33.1138 4.18359C31.6877 4.18359 30.7373 5.06342 30.7373 6.40352C30.7373 7.74482 31.6877 8.63161 33.1138 8.63161ZM33.1138 5.29064C33.6862 5.29064 34.0806 5.75095 34.0806 6.42101C34.0806 7.09921 33.6862 7.55136 33.1138 7.55136C32.5333 7.55136 32.1391 7.09921 32.1391 6.42101C32.1391 5.75095 32.5346 5.29064 33.1138 5.29064Z" fill="white"/>
    <path d="M37.9818 8.63851C38.5379 8.63851 38.9809 8.41245 39.287 8.00931V8.58257H40.6803V2.59473H39.287V4.80625C38.9809 4.40307 38.5217 4.17704 37.9494 4.17704C36.7489 4.17704 35.9512 5.05673 35.9512 6.38854C35.9522 7.73548 36.7572 8.63851 37.9818 8.63851ZM38.3203 5.28979C38.9007 5.28979 39.287 5.75004 39.287 6.41999C39.287 7.08998 38.9007 7.55839 38.3203 7.55839C37.7399 7.55839 37.3538 7.08998 37.3457 6.41999C37.3538 5.75004 37.7399 5.28979 38.3203 5.28979Z" fill="white"/>
    <path d="M43.767 8.63142C44.565 8.63142 45.2176 8.34825 45.6607 7.86465L44.9279 7.14681C44.6384 7.43697 44.2672 7.59081 43.8892 7.59081C43.3738 7.59081 43.0028 7.30065 42.8654 6.78323H45.919C46.0157 5.19375 45.2584 4.18457 43.7275 4.18457C42.3816 4.18457 41.4639 5.07256 41.4639 6.42082C41.4626 7.74463 42.349 8.63142 43.767 8.63142ZM43.7427 5.15296C44.2417 5.15296 44.5802 5.50021 44.5802 6.03276H42.8399C42.9284 5.50838 43.2586 5.15296 43.7427 5.15296Z" fill="white"/>
    <path d="M49.7089 2.59473V4.80625C49.403 4.40307 48.9436 4.17704 48.3713 4.17704C47.171 4.17704 46.373 5.05674 46.373 6.38854C46.373 7.73664 47.1791 8.63968 48.4029 8.63968C48.9587 8.63968 49.402 8.41362 49.7079 8.01047V8.58374H51.1012V2.5959H49.7089V2.59473ZM48.7424 7.55722C48.162 7.55722 47.7759 7.08881 47.7679 6.41883C47.7759 5.74887 48.162 5.28862 48.7424 5.28862C49.3228 5.28862 49.7089 5.74887 49.7089 6.41883C49.7089 7.08881 49.3228 7.55722 48.7424 7.55722Z" fill="white"/>
    <path d="M26.6278 12.7774H29.4715V12.1564H26.6278V10.3002H29.8101V9.6792H25.9834V15.328H29.9148V14.7139H26.6278V12.7774Z" fill="white"/>
    <path d="M31.4425 12.1565C31.4425 11.7614 31.8053 11.5913 32.28 11.5913C32.6742 11.5913 33.1011 11.7288 33.4316 11.9386L33.6735 11.4387C33.3187 11.1963 32.8278 11.0518 32.3195 11.0518C31.546 11.0518 30.8456 11.3909 30.8456 12.2054C30.8375 13.7145 33.214 13.1901 33.1897 14.2156C33.1813 14.6351 32.779 14.8216 32.2788 14.8216C31.7798 14.8216 31.2317 14.62 30.8689 14.2809L30.627 14.7575C31.0295 15.1444 31.6505 15.3541 32.2542 15.3541C33.0675 15.3541 33.8096 14.9836 33.8096 14.1433C33.8106 12.6168 31.4425 13.1004 31.4425 12.1565Z" fill="white"/>
    <path d="M36.2075 14.7471C35.8444 14.7471 35.6432 14.5537 35.6432 14.0363V11.7196H36.9891V11.2034L35.6432 11.2116V10.0894H35.0233V11.2116H34.4102V11.7208H35.0233V14.1505C35.0233 14.9895 35.4828 15.3613 36.1027 15.3613C36.4413 15.3531 36.7634 15.2401 37.0612 15.0385L36.8843 14.5292C36.6505 14.6749 36.4248 14.7471 36.2075 14.7471Z" fill="white"/>
    <path d="M39.6045 11.0508C38.8795 11.0508 38.4203 11.2605 37.8722 11.6241L38.1386 12.0844C38.5819 11.7779 39.0169 11.5997 39.4833 11.5997C40.2325 11.5997 40.6199 11.9702 40.6199 12.5925V12.8431H39.2262C38.2269 12.8512 37.6396 13.3185 37.6396 14.062C37.6396 14.7961 38.2039 15.3613 39.0819 15.3613C39.7591 15.3613 40.2988 15.1352 40.6373 14.6749V15.3287H41.2504L41.2421 12.5202C41.233 11.6078 40.6525 11.0508 39.6045 11.0508ZM40.628 13.8592C40.4663 14.4489 39.9185 14.8113 39.2019 14.8113C38.6052 14.8113 38.2269 14.5048 38.2269 14.0363C38.2269 13.5679 38.5981 13.3499 39.3066 13.3499H40.628V13.8592Z" fill="white"/>
    <path d="M43.8383 14.7471C43.4752 14.7471 43.274 14.5537 43.274 14.0363V11.7196H44.6199V11.2034L43.274 11.2116V10.0894H42.6541V11.2116H42.041V11.7208H42.6541V14.1505C42.6541 14.9895 43.1136 15.3613 43.7336 15.3613C44.0721 15.3531 44.3942 15.2401 44.692 15.0385L44.5152 14.5292C44.2814 14.6749 44.0557 14.7471 43.8383 14.7471Z" fill="white"/>
    <path d="M47.1869 11.0586C45.9539 11.0586 45.084 11.9385 45.084 13.2063C45.084 14.473 45.9458 15.3528 47.1953 15.3528C47.9048 15.3528 48.5004 15.0953 48.904 14.6432L48.5574 14.2726C48.2269 14.6199 47.7674 14.8051 47.2441 14.8051C46.4228 14.8051 45.818 14.2808 45.7052 13.4487H49.1705C49.2342 12.0026 48.5574 11.0505 47.1869 11.0586ZM45.7052 12.9313C45.8016 12.1237 46.382 11.5994 47.1882 11.5994C48.0187 11.5994 48.5655 12.1156 48.6062 12.9313H45.7052Z" fill="white"/>
    <path d="M8.36326 16.2824L11.2524 1.42408C11.3361 0.995291 11.6886 0.703997 12.0398 0.772743C12.341 0.831001 12.5435 1.13511 12.5435 1.49049C12.5435 1.54992 12.5376 1.61167 12.526 1.67342L9.63685 16.5317C9.55311 16.9605 9.2007 17.2518 8.84943 17.1831C8.54821 17.1248 8.34583 16.8207 8.34583 16.4653C8.34583 16.4059 8.35162 16.3442 8.36326 16.2824Z" fill="white"/>
    <path d="M9.68965 3.79054C9.62566 4.12145 9.33605 4.36031 8.99991 4.36031H5.95841L5.94563 4.36148L1.93759 8.97674L5.95262 13.6002L5.95841 13.5943H6.93078C7.37275 13.5943 7.70539 13.9987 7.62049 14.4333C7.55653 14.7642 7.26692 15.003 6.93078 15.003H5.95841C5.58156 15.003 5.21519 14.8539 4.95351 14.5929L0.875681 9.89957C0.633758 9.64789 0.5 9.32164 0.5 8.97674C0.5 8.63186 0.632594 8.30562 0.875681 8.0551L4.91862 3.39906L4.95467 3.36061C5.21752 3.10077 5.58389 2.95162 5.95957 2.95162H9.00107C9.44191 2.95162 9.77455 3.35594 9.68965 3.79054Z" fill="white"/>
    <path d="M15.9351 3.36188L17.2482 4.87311H19.1883C19.4488 4.87311 19.6605 5.08517 19.6605 5.34618V5.90312C19.6605 6.16413 19.4488 6.3762 19.1883 6.3762H18.5532L20.0129 8.05638C20.2548 8.30806 20.3886 8.6343 20.3886 8.97801C20.3886 9.32292 20.256 9.64916 20.0129 9.89968L15.97 14.5546L15.9339 14.593C15.6711 14.854 15.3047 15.0031 14.9278 15.0031H11.8875C11.4455 15.0031 11.1129 14.5988 11.1966 14.1642C11.2606 13.8333 11.5502 13.5944 11.8875 13.5944L14.9418 13.5933L18.9498 8.97801L14.936 4.35462L14.929 4.36042H13.9566C13.5147 4.36042 13.182 3.95611 13.2669 3.52151C13.3309 3.19059 13.6205 2.95173 13.9566 2.95173H14.929C15.3059 2.95173 15.6722 3.10204 15.9351 3.36188Z" fill="white"/>
    <path d="M14.7523 7.61L15.7456 8.75304C15.8049 8.81478 15.8375 8.89401 15.8375 8.9779C15.8375 9.06179 15.8049 9.14102 15.7456 9.20279L14.7604 10.3365L14.7523 10.3458C14.6883 10.4088 14.5988 10.446 14.5069 10.446H13.8276C13.6648 10.446 13.5334 10.3144 13.5334 10.1512V7.80458C13.5334 7.64146 13.6648 7.50979 13.8276 7.50979H14.5069C14.5988 7.50979 14.6883 7.54591 14.7523 7.61Z" fill="white"/>
    <path d="M6.65152 10.3386L5.44538 9.80027C5.39536 9.77815 5.3628 9.72804 5.3628 9.67327V8.28089C5.3628 8.22612 5.39536 8.17603 5.44538 8.15388L6.65152 7.61557C6.72828 7.58178 6.81436 7.58178 6.89112 7.61557L8.09726 8.15388C8.14725 8.17603 8.17984 8.22612 8.17984 8.28089V9.67327C8.17984 9.72804 8.14725 9.77815 8.09726 9.80027L6.89112 10.3386C6.81436 10.3735 6.72712 10.3735 6.65152 10.3386Z" fill="white"/>
    </g>
    <line x1="61.0137" y1="1.93945" x2="61.0137" y2="16.0611" stroke="white" stroke-opacity="0.5"/>
    <path d="M111.879 15.7354C109.306 15.7354 108.241 14.0318 108.17 12.8073H109.288C109.412 13.6768 110.477 14.7416 111.879 14.7416C113.192 14.7416 114.221 13.943 114.221 12.825C114.221 11.6005 113.086 11.1746 111.595 10.9261C110.069 10.6777 108.525 10.1453 108.525 8.38838C108.525 6.64923 110.158 5.69092 111.826 5.69092C113.92 5.69092 115.091 6.63148 115.286 8.33514H114.221C113.991 7.0219 112.607 6.73796 112.039 6.72021C110.938 6.68472 109.803 7.14613 109.803 8.22866C109.803 9.41768 110.956 9.71937 112.234 9.91458C113.707 10.1453 115.499 10.7664 115.499 12.7363C115.499 14.4044 113.938 15.7354 111.879 15.7354Z" fill="white"/>
    <path d="M105.156 15.575L105.174 2.26514H106.363V15.575H105.156Z" fill="white"/>
    <path d="M103.247 10.6415C103.247 10.7835 103.247 10.9609 103.229 11.1384H95.6693C95.7048 13.4455 97.4262 14.599 98.8282 14.6167C100.55 14.6345 101.472 13.7826 101.756 12.7533H102.874C102.732 13.7649 101.721 15.6815 98.9524 15.6638C96.184 15.646 94.498 13.4277 94.498 10.7125C94.498 7.9263 96.1485 5.76123 98.9169 5.76123C101.597 5.76123 103.247 7.78433 103.247 10.6415ZM98.9347 6.84377C97.0181 6.84377 95.9 8.21024 95.6871 10.1269H102.111C101.934 8.1925 100.816 6.84377 98.9347 6.84377Z" fill="white"/>
    <path d="M92.6027 15.5927C91.1297 15.5927 90.0472 15.0071 90.0472 13.1615L90.0649 6.77274H88.5742V5.77894H90.0649V2.26514H91.3072V5.77894H93.49V6.79049L91.2539 6.77274V12.984C91.2539 13.7649 91.3072 14.4747 92.6027 14.4747H93.632V15.5927H92.6027Z" fill="white"/>
    <path d="M83.6154 15.6998C80.847 15.6998 78.9658 13.4637 78.9658 10.6775C78.9658 7.89133 80.847 5.65527 83.6154 5.65527C86.4016 5.65527 88.2827 7.89133 88.2827 10.6775C88.2827 13.4637 86.4016 15.6998 83.6154 15.6998ZM83.6154 14.564C85.7095 14.564 87.0227 12.8248 87.0227 10.6775C87.0227 8.51246 85.7095 6.79105 83.6154 6.79105C81.5391 6.79105 80.2081 8.51246 80.2081 10.6775C80.2081 12.8248 81.5391 14.564 83.6154 14.564Z" fill="white"/>
    <path d="M74.5773 5.70827C76.6892 5.70827 78.1266 7.11024 78.1266 9.25757V15.5753H76.8489V9.38179C76.8489 7.8556 75.8196 6.84405 74.2579 6.84405C72.6784 6.84405 71.2055 7.89109 71.2055 9.43503V15.5753H69.9277V2.2832H71.2055V7.30546C71.8976 6.3294 73.2286 5.70827 74.5773 5.70827Z" fill="white"/>
    <defs>
    <clipPath id="clip0_582_36937">
    <rect width="50.6006" height="16.4746" fill="white" transform="translate(0.5 0.762695)"/>
    </clipPath>
    </defs>
    </svg>
    </div>
  )
}

export default Coded