import React from 'react'

function Symbol() {
  return (
    <div><svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_600_37122)">
    <g filter="url(#filter0_ii_600_37122)">
    <circle cx="4.89941" cy="4" r="4" fill="url(#paint0_radial_600_37122)"/>
    </g>
    </g>
    <defs>
    <filter id="filter0_ii_600_37122" x="0.899414" y="0" width="8" height="13" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="2"/>
    <feGaussianBlur stdDeviation="2"/>
    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.31 0"/>
    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_600_37122"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="8"/>
    <feGaussianBlur stdDeviation="2.5"/>
    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
    <feBlend mode="normal" in2="effect1_innerShadow_600_37122" result="effect2_innerShadow_600_37122"/>
    </filter>
    <radialGradient id="paint0_radial_600_37122" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(4.89941 8) rotate(-90) scale(8)">
    <stop offset="0.125" stop-color="#FAEAEA"/>
    <stop offset="1" stop-color="#E20808"/>
    </radialGradient>
    <clipPath id="clip0_600_37122">
    <rect x="0.899414" width="8" height="8" rx="4" fill="white"/>
    </clipPath>
    </defs>
    </svg>
    </div>
  )
}

export default Symbol