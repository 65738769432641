import React, { useState, useEffect } from "react";
import Hotels from "./Hotels";
import Symbol from "./Symbol";
import Text from "./Text";
import BookNow from "./BookNow";

function Topbar() {
  const [isVisible, setIsVisible] = useState(true);

  // Handle closing the banner
  const closeBanner = () => {
    setIsVisible(false);
  };

  // Apply the push-down effect to the main content
  useEffect(() => {
    // Get the main content element (the parent div in your App component)
    const mainContent = document.querySelector('.App') || document.body;
    
    if (isVisible) {
      // Add padding to the top of the main content when banner is visible
      mainContent.style.paddingTop = "10vh";
      mainContent.style.transition = "padding-top 0.3s ease";
    } else {
      // Remove padding when banner is closed
      mainContent.style.paddingTop = "0";
    }
    
    // Cleanup function
    return () => {
      mainContent.style.paddingTop = "0";
    };
  }, [isVisible]);

  // If banner is not visible, don't render anything
  if (!isVisible) return null;

  return (
    <div 
      className="fixed top-0 left-0 w-full z-50 flex items-center justify-between px-4 md:px-8"
      style={{ 
        height: window.innerWidth <= 768 ? "6vh" : "8vh", 
        background: "linear-gradient(180deg, #5B1DEE 0%, #341188 100%), linear-gradient(180deg, rgba(0, 0, 0, 0) 32.81%, rgba(0, 0, 0, 0.1) 100%)",
        transition: "transform 0.3s ease"
      }}
    >
        <div className="hidden md:block">
          <Hotels/>
        </div>
      <div className="flex text-white font-medium">
        <span className="mt-1"><Symbol/></span><Text/>
        <button onClick={() => window.open('https://hotels.codedestate.com', '_blank')}>
          <BookNow/>
        </button>
      </div>
      
      <button 
        onClick={closeBanner}
        className="text-white hover:text-gray-200 focus:outline-none"
      >
        <svg 
          xmlns="http://www.w3.org/2000/svg" 
          width="24" 
          height="24" 
          viewBox="0 0 24 24" 
          fill="none" 
          stroke="currentColor" 
          strokeWidth="2" 
          strokeLinecap="round" 
          strokeLinejoin="round"
        >
          <line x1="18" y1="6" x2="6" y2="18"></line>
          <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
      </button>
    </div>
  );
}

export default Topbar;