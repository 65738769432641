import React from 'react'

function Flag() {
  return (
    <div>   <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 11.25C9.9471 11.25 8.267 9.57068 8.267 7.517H6.767C6.767 10.3993 9.1189 12.75 12 12.75V11.25ZM8.267 7.517C8.267 5.45594 9.95445 3.75 12 3.75V2.25C9.11155 2.25 6.767 4.64206 6.767 7.517H8.267ZM12 3.75C14.0529 3.75 15.733 5.42932 15.733 7.483H17.233C17.233 4.60068 14.8811 2.25 12 2.25V3.75ZM15.733 7.483C15.733 9.54406 14.0456 11.25 12 11.25V12.75C14.8884 12.75 17.233 10.3579 17.233 7.483H15.733ZM19 20.25H5V21.75H19V20.25ZM5 20.25C4.86421 20.25 4.75 20.1358 4.75 20H3.25C3.25 20.9642 4.03579 21.75 5 21.75V20.25ZM4.75 20V19H3.25V20H4.75ZM4.75 19C4.75 17.2142 6.21421 15.75 8 15.75V14.25C5.38579 14.25 3.25 16.3858 3.25 19H4.75ZM8 15.75H16V14.25H8V15.75ZM16 15.75C17.7858 15.75 19.25 17.2142 19.25 19H20.75C20.75 16.3858 18.6142 14.25 16 14.25V15.75ZM19.25 19V20H20.75V19H19.25ZM19.25 20C19.25 20.1358 19.1358 20.25 19 20.25V21.75C19.9642 21.75 20.75 20.9642 20.75 20H19.25Z"
      fill="#323232"
    />
  </svg></div>
  )
}

export default Flag