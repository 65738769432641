import React, { useState } from "react";

const SecondFloatingAlert = ({
  onClose,
  onClick,
  url = "https://hotels.codedestate.com",
}) => {
  const [isVisible, setIsVisible] = useState(true);

  const handleClose = (e) => {
    e.stopPropagation();
    setIsVisible(false);
    if (onClose) onClose();
  };

  const handleClick = () => {
    if (url) window.open(url);
    if (onClick) onClick();
  };

  if (!isVisible) return null;

  return (
    <div
      onClick={handleClick}
      className="fixed sm:mt-20 mt-40 floatingAnimation cursor-pointer top-[20px] right-4 w-[60vw] sm:w-[240px] z-[90] shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,255,0.8)] rounded-lg border-[1px] border-blue-300 p-4"
      style={{
        background:
          "linear-gradient(180deg, #AB68FF 0%, #7637DD 100%), linear-gradient(180deg, rgba(0, 0, 0, 0) 32.81%, rgba(0, 0, 0, 0.1) 100%)",
      }}
    >
      <div
        onClick={handleClose}
        className="absolute top-2 right-2 w-[24px] h-[24px] cursor-pointer hover:bg-blue-600 rounded-full flex items-center justify-center"
      >
        <svg
          width="14"
          height="14"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.92395 8.21999C8.97128 8.26693 8.9979 8.33083 8.9979 8.39749C8.9979 8.46415 8.97128 8.52805 8.92395 8.57499L8.57395 8.92499C8.52701 8.97232 8.46311 8.99894 8.39645 8.99894C8.32979 8.99894 8.26589 8.97232 8.21895 8.92499L5.99895 6.70499L3.77895 8.92499C3.73201 8.97232 3.66811 8.99894 3.60145 8.99894C3.53479 8.99894 3.47089 8.97232 3.42395 8.92499L3.07395 8.57499C3.02662 8.52805 3 8.46415 3 8.39749C3 8.33083 3.02662 8.26693 3.07395 8.21999L5.29395 5.99999L3.07395 3.77999C3.02662 3.73305 3 3.66915 3 3.60249C3 3.53583 3.02662 3.47193 3.07395 3.42499L3.42395 3.07499C3.47089 3.02766 3.53479 3.00104 3.60145 3.00104C3.66811 3.00104 3.73201 3.02766 3.77895 3.07499L5.99895 5.29499L8.21895 3.07499C8.26589 3.02766 8.32979 3.00104 8.39645 3.00104C8.46311 3.00104 8.52701 3.02766 8.57395 3.07499L8.92395 3.42499C8.97128 3.47193 8.9979 3.53583 8.9979 3.60249C8.9979 3.66915 8.97128 3.73305 8.92395 3.77999L6.70395 5.99999L8.92395 8.21999Z"
            fill="#ffffff"
          />
        </svg>
      </div>
      <div className="flex flex-col items-center justify-center">
        <div className="w-12 h-12 mb-2">
          <svg
            width="42"
            height="38"
            viewBox="0 0 42 38"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g filter="url(#filter0_d_574_36362)">
              <path
                d="M27.6665 25.3333H30.3332V14.6667H22.3332V25.3333H24.9998V17.3333H27.6665V25.3333ZM8.99984 25.3333V5.33333C8.99984 4.59696 9.5968 4 10.3332 4H28.9998C29.7362 4 30.3332 4.59696 30.3332 5.33333V12H32.9998V25.3333H34.3332V28H7.6665V25.3333H8.99984ZM14.3332 14.6667V17.3333H16.9998V14.6667H14.3332ZM14.3332 20V22.6667H16.9998V20H14.3332ZM14.3332 9.33333V12H16.9998V9.33333H14.3332Z"
                fill="url(#paint0_radial_574_36362)"
                shape-rendering="crispEdges"
              />
              <path
                d="M27.4165 25.3333V25.5833H27.6665H30.3332H30.5832V25.3333V14.6667V14.4167H30.3332H22.3332H22.0832V14.6667V25.3333V25.5833H22.3332H24.9998H25.2498V25.3333V17.5833H27.4165V25.3333ZM8.99984 25.5833H9.24984V25.3333V5.33333C9.24984 4.73503 9.73487 4.25 10.3332 4.25H28.9998C29.5982 4.25 30.0832 4.73503 30.0832 5.33333V12V12.25H30.3332H32.7498V25.3333V25.5833H32.9998H34.0832V27.75H7.9165V25.5833H8.99984ZM14.3332 14.4167H14.0832V14.6667V17.3333V17.5833H14.3332H16.9998H17.2498V17.3333V14.6667V14.4167H16.9998H14.3332ZM14.3332 19.75H14.0832V20V22.6667V22.9167H14.3332H16.9998H17.2498V22.6667V20V19.75H16.9998H14.3332ZM14.3332 9.08333H14.0832V9.33333V12V12.25H14.3332H16.9998H17.2498V12V9.33333V9.08333H16.9998H14.3332Z"
                stroke="url(#paint1_linear_574_36362)"
                stroke-width="0.5"
                shape-rendering="crispEdges"
              />
            </g>
            <defs>
              <filter
                id="filter0_d_574_36362"
                x="0.666504"
                y="0"
                width="40.6665"
                height="38"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="3" />
                <feGaussianBlur stdDeviation="3.5" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.22 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_574_36362"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_574_36362"
                  result="shape"
                />
              </filter>
              <radialGradient
                id="paint0_radial_574_36362"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(20.9998 2.85714) rotate(90) scale(41.7143 46.3492)"
              >
                <stop stop-color="white" />
                <stop offset="1" stop-color="white" stop-opacity="0" />
              </radialGradient>
              <linearGradient
                id="paint1_linear_574_36362"
                x1="20.9998"
                y1="4"
                x2="20.9998"
                y2="28"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0.735221" stop-color="white" stop-opacity="0" />
                <stop offset="1" stop-color="white" stop-opacity="0.8" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div className="text-center">
          <h3 className="text-white font-bold text-xl mb-1">Coded Estate</h3>
          <p className="text-white text-lg font-semibold">Hotels is Live</p>
        </div>
      </div>
    </div>
  );
};

export default SecondFloatingAlert;
