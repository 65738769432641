import React from 'react'

function Hotels() {
  return (
    <div><svg width="152" height="22" viewBox="0 0 152 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_600_36421)">
    <path d="M36.578 8.93389C35.3974 8.93389 34.5037 8.01377 34.5037 6.80236C34.5037 5.61184 35.3974 4.70215 36.578 4.70215C37.2365 4.70215 37.9596 5.06366 38.4495 5.64323L39.5442 4.38103C38.7995 3.63565 37.651 3.16064 36.5456 3.16064C34.2813 3.16064 32.5693 4.73354 32.5693 6.82325C32.5693 8.94435 34.2382 10.5471 36.4828 10.5471C37.5789 10.5471 38.7581 10.0198 39.5566 9.22219L38.4512 8.08395C37.9292 8.6023 37.2058 8.93389 36.578 8.93389Z" fill="white"/>
    <path d="M43.0501 10.527C44.9218 10.527 46.178 9.38861 46.178 7.6706C46.178 5.95262 44.9235 4.82471 43.0501 4.82471C41.1676 4.82471 39.9131 5.95262 39.9131 7.6706C39.9131 9.39011 41.1676 10.527 43.0501 10.527ZM43.0501 6.24392C43.8056 6.24392 44.3263 6.83402 44.3263 7.69302C44.3263 8.56246 43.8056 9.1421 43.0501 9.1421C42.2838 9.1421 41.7635 8.56246 41.7635 7.69302C41.7635 6.83402 42.2855 6.24392 43.0501 6.24392Z" fill="white"/>
    <path d="M49.4763 10.5361C50.2103 10.5361 50.7951 10.2463 51.1992 9.72945V10.4644H53.0383V2.78809H51.1992V5.6232C50.7951 5.10634 50.189 4.81658 49.4335 4.81658C47.8489 4.81658 46.7959 5.94432 46.7959 7.65166C46.7972 9.3784 47.8599 10.5361 49.4763 10.5361ZM49.9231 6.24309C50.6892 6.24309 51.1992 6.83312 51.1992 7.69198C51.1992 8.55089 50.6892 9.15138 49.9231 9.15138C49.157 9.15138 48.6474 8.55089 48.6367 7.69198C48.6474 6.83312 49.157 6.24309 49.9231 6.24309Z" fill="white"/>
    <path d="M57.1133 10.5269C58.1668 10.5269 59.0282 10.1639 59.613 9.54395L58.6458 8.62369C58.2637 8.99567 57.7737 9.19289 57.2747 9.19289C56.5943 9.19289 56.1047 8.82091 55.9233 8.15759H59.9541C60.0816 6.11991 59.082 4.82617 57.0612 4.82617C55.2847 4.82617 54.0732 5.96455 54.0732 7.69299C54.0716 9.39008 55.2416 10.5269 57.1133 10.5269ZM57.0813 6.06763C57.7399 6.06763 58.1868 6.51279 58.1868 7.19551H55.8896C56.0065 6.52326 56.4423 6.06763 57.0813 6.06763Z" fill="white"/>
    <path d="M64.956 2.78809V5.6232C64.5523 5.10634 63.9458 4.81658 63.1904 4.81658C61.6061 4.81658 60.5527 5.94432 60.5527 7.65166C60.5527 9.3799 61.6167 10.5376 63.2321 10.5376C63.9658 10.5376 64.5509 10.2478 64.9547 9.73094V10.4659H66.7938V2.78958H64.956V2.78809ZM63.6803 9.14988C62.9142 9.14988 62.4046 8.54939 62.3939 7.69049C62.4046 6.83162 62.9142 6.24159 63.6803 6.24159C64.4464 6.24159 64.956 6.83162 64.956 7.69049C64.956 8.54939 64.4464 9.14988 63.6803 9.14988Z" fill="white"/>
    <path d="M34.4893 15.8419H38.243V15.0458H34.4893V12.6663H38.6899V11.8701H33.6387V19.1117H38.8281V18.3245H34.4893V15.8419Z" fill="white"/>
    <path d="M40.8441 15.0461C40.8441 14.5397 41.323 14.3216 41.9496 14.3216C42.47 14.3216 43.0335 14.4979 43.4697 14.7668L43.789 14.1259C43.3207 13.8151 42.6728 13.6299 42.0017 13.6299C40.9807 13.6299 40.0562 14.0646 40.0562 15.1089C40.0455 17.0435 43.1824 16.3712 43.1504 17.6859C43.1394 18.2237 42.6083 18.4627 41.948 18.4627C41.2893 18.4627 40.5658 18.2043 40.0869 17.7695L39.7676 18.3805C40.299 18.8765 41.1186 19.1454 41.9156 19.1454C42.9891 19.1454 43.9687 18.6703 43.9687 17.5932C43.97 15.6362 40.8441 16.2562 40.8441 15.0461Z" fill="white"/>
    <path d="M47.1342 18.3676C46.6549 18.3676 46.3893 18.1197 46.3893 17.4564V14.4864H48.1659V13.8247L46.3893 13.8351V12.3965H45.571V13.8351H44.7617V14.4879H45.571V17.6027C45.571 18.6784 46.1776 19.1549 46.9959 19.1549C47.4428 19.1445 47.868 18.9996 48.2611 18.7411L48.0276 18.0883C47.719 18.275 47.4211 18.3676 47.1342 18.3676Z" fill="white"/>
    <path d="M51.618 13.6284C50.661 13.6284 50.0548 13.8973 49.3314 14.3634L49.6831 14.9535C50.2682 14.5606 50.8424 14.3321 51.458 14.3321C52.447 14.3321 52.9583 14.8071 52.9583 15.6049V15.9261H51.1186C49.7996 15.9365 49.0244 16.5356 49.0244 17.4887C49.0244 18.4298 49.7692 19.1544 50.9282 19.1544C51.822 19.1544 52.5345 18.8646 52.9814 18.2745V19.1126H53.7907L53.7796 15.5122C53.7676 14.3425 53.0014 13.6284 51.618 13.6284ZM52.969 17.2287C52.7556 17.9847 52.0325 18.4493 51.0866 18.4493C50.299 18.4493 49.7996 18.0564 49.7996 17.4558C49.7996 16.8553 50.2896 16.5759 51.2248 16.5759H52.969V17.2287Z" fill="white"/>
    <path d="M57.2074 18.3676C56.7281 18.3676 56.4626 18.1197 56.4626 17.4564V14.4864H58.2391V13.8247L56.4626 13.8351V12.3965H55.6443V13.8351H54.835V14.4879H55.6443V17.6027C55.6443 18.6784 56.2508 19.1549 57.0691 19.1549C57.516 19.1445 57.9412 18.9996 58.3343 18.7411L58.1009 18.0883C57.7922 18.275 57.4943 18.3676 57.2074 18.3676Z" fill="white"/>
    <path d="M61.6265 13.6387C59.9989 13.6387 58.8506 14.7667 58.8506 16.392C58.8506 18.0159 59.9882 19.1438 61.6375 19.1438C62.5741 19.1438 63.3603 18.8136 63.8931 18.234L63.4355 17.7589C62.9993 18.2041 62.3927 18.4417 61.702 18.4417C60.6178 18.4417 59.8195 17.7694 59.6706 16.7027H64.2448C64.3289 14.8488 63.4355 13.6283 61.6265 13.6387ZM59.6706 16.0395C59.7978 15.0042 60.564 14.3319 61.6282 14.3319C62.7244 14.3319 63.4462 14.9937 63.4999 16.0395H59.6706Z" fill="white"/>
    <path d="M10.3795 20.3358L14.1932 1.28782C14.3037 0.738123 14.7689 0.364692 15.2326 0.452822C15.6302 0.527508 15.8974 0.91737 15.8974 1.37296C15.8974 1.44914 15.8897 1.5283 15.8743 1.60747L12.0606 20.6555C11.9501 21.2052 11.4849 21.5786 11.0213 21.4905C10.6236 21.4158 10.3565 21.0259 10.3565 20.5704C10.3565 20.4942 10.3641 20.415 10.3795 20.3358Z" fill="white"/>
    <path d="M12.1303 4.32157C12.0459 4.74579 11.6636 5.052 11.2199 5.052H7.2051L7.18824 5.05349L1.89762 10.9701L7.19745 16.8973L7.2051 16.8898H8.48862C9.07203 16.8898 9.51112 17.4081 9.39904 17.9653C9.31462 18.3895 8.93233 18.6957 8.48862 18.6957H7.2051C6.70766 18.6957 6.22405 18.5045 5.87863 18.1699L0.495899 12.1532C0.17656 11.8305 0 11.4123 0 10.9701C0 10.528 0.175025 10.1098 0.495899 9.78863L5.83258 3.81969L5.88017 3.7704C6.22712 3.43729 6.71073 3.24609 7.20664 3.24609H11.2214C11.8033 3.24609 12.2424 3.76442 12.1303 4.32157Z" fill="white"/>
    <path d="M20.3743 3.77204L22.1076 5.70939H24.6685C25.0124 5.70939 25.2918 5.98125 25.2918 6.31586V7.02985C25.2918 7.36445 25.0124 7.63631 24.6685 7.63631H23.8302L25.757 9.79026C26.0764 10.1129 26.2529 10.5312 26.2529 10.9718C26.2529 11.4139 26.0779 11.8322 25.757 12.1533L20.4203 18.1208L20.3728 18.1701C20.0258 18.5047 19.5422 18.6959 19.0447 18.6959H15.0315C14.4481 18.6959 14.009 18.1775 14.1195 17.6204C14.204 17.1962 14.5863 16.8899 15.0315 16.8899L19.0632 16.8884L24.3538 10.9718L19.0555 5.0447L19.0463 5.05214H17.7628C17.1794 5.05214 16.7403 4.53382 16.8523 3.97667C16.9368 3.55245 17.3191 3.24623 17.7628 3.24623H19.0463C19.5437 3.24623 20.0273 3.43893 20.3743 3.77204Z" fill="white"/>
    <path d="M18.813 9.21803L20.1242 10.6834C20.2025 10.7625 20.2454 10.8641 20.2454 10.9716C20.2454 11.0792 20.2025 11.1808 20.1242 11.2599L18.8238 12.7133L18.813 12.7253C18.7286 12.806 18.6104 12.8538 18.4891 12.8538H17.5925C17.3775 12.8538 17.204 12.685 17.204 12.4758V9.46747C17.204 9.25835 17.3775 9.08955 17.5925 9.08955H18.4891C18.6104 9.08955 18.7286 9.13585 18.813 9.21803Z" fill="white"/>
    <path d="M8.12001 12.716L6.5279 12.0259C6.46188 11.9975 6.4189 11.9333 6.4189 11.8631V10.0781C6.4189 10.0079 6.46188 9.94366 6.5279 9.91526L8.12001 9.22516C8.22133 9.18185 8.33495 9.18185 8.43627 9.22516L10.0284 9.91526C10.0944 9.94366 10.1374 10.0079 10.1374 10.0781V11.8631C10.1374 11.9333 10.0944 11.9975 10.0284 12.0259L8.43627 12.716C8.33495 12.7608 8.2198 12.7608 8.12001 12.716Z" fill="white"/>
    </g>
    <line x1="79.8778" y1="1.94873" x2="79.8778" y2="20.0523" stroke="white" stroke-opacity="0.5" stroke-width="1.32"/>
    <path d="M147.021 19.6341C143.624 19.6341 142.219 17.4501 142.125 15.8803H143.601C143.765 16.9951 145.17 18.3601 147.021 18.3601C148.754 18.3601 150.113 17.3363 150.113 15.903C150.113 14.3333 148.614 13.7872 146.646 13.4687C144.632 13.1502 142.594 12.4677 142.594 10.2154C142.594 7.98585 144.749 6.75732 146.951 6.75732C149.715 6.75732 151.261 7.9631 151.519 10.1472H150.113C149.809 8.46362 147.981 8.09961 147.232 8.07686C145.779 8.03135 144.28 8.62287 144.28 10.0107C144.28 11.5349 145.803 11.9217 147.489 12.172C149.434 12.4677 151.8 13.264 151.8 15.7893C151.8 17.9278 149.738 19.6341 147.021 19.6341Z" fill="white"/>
    <path d="M138.146 19.4286L138.17 2.36572H139.739V19.4286H138.146Z" fill="white"/>
    <path d="M135.627 13.1041C135.627 13.2861 135.627 13.5136 135.603 13.7411H125.624C125.671 16.6986 127.943 18.1774 129.794 18.2002C132.066 18.2229 133.284 17.1309 133.659 15.8114H135.135C134.948 17.1082 133.612 19.5652 129.958 19.5425C126.304 19.5197 124.078 16.6759 124.078 13.1951C124.078 9.62322 126.257 6.84766 129.911 6.84766C133.448 6.84766 135.627 9.44122 135.627 13.1041ZM129.934 8.23544C127.405 8.23544 125.929 9.98723 125.648 12.4443H134.128C133.893 9.96448 132.418 8.23544 129.934 8.23544Z" fill="white"/>
    <path d="M121.576 19.4514C119.632 19.4514 118.203 18.7006 118.203 16.3346L118.227 8.14436H116.259V6.87033H118.227V2.36572H119.866V6.87033H122.748V8.16711L119.796 8.14436V16.1071C119.796 17.1081 119.866 18.0181 121.576 18.0181H122.935V19.4514H121.576Z" fill="white"/>
    <path d="M109.713 19.5882C106.058 19.5882 103.575 16.7217 103.575 13.1498C103.575 9.578 106.058 6.71143 109.713 6.71143C113.39 6.71143 115.874 9.578 115.874 13.1498C115.874 16.7217 113.39 19.5882 109.713 19.5882ZM109.713 18.1322C112.477 18.1322 114.21 15.9026 114.21 13.1498C114.21 10.3743 112.477 8.16746 109.713 8.16746C106.972 8.16746 105.215 10.3743 105.215 13.1498C105.215 15.9026 106.972 18.1322 109.713 18.1322Z" fill="white"/>
    <path d="M97.783 6.78002C100.571 6.78002 102.468 8.57731 102.468 11.3301V19.4293H100.781V11.4894C100.781 9.53283 99.4227 8.23605 97.3613 8.23605C95.2764 8.23605 93.3321 9.57833 93.3321 11.5576V19.4293H91.6455V2.38916H93.3321V8.82757C94.2457 7.57629 96.0026 6.78002 97.783 6.78002Z" fill="white"/>
    <defs>
    <clipPath id="clip0_600_36421">
    <rect width="66.7928" height="21.12" fill="white" transform="translate(0 0.439941)"/>
    </clipPath>
    </defs>
    </svg>
    </div>
  )
}

export default Hotels