import React from 'react'

function LiveSvg() {
  return (
    <div className="absolute -top-4 left-1/2 -translate-x-1/2 z-10">
    <div className="flex items-center gap-2 bg-white px-3 py-1 rounded-full shadow-md">
      <span className="text-[#202020] font-medium">Live</span>
      <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_ii_600_36387)">
<circle cx="4" cy="4" r="4" fill="url(#paint0_radial_600_36387)"/>
</g>
<circle cx="4" cy="4" r="3" stroke="url(#paint1_radial_600_36387)" stroke-width="2"/>
<defs>
<filter id="filter0_ii_600_36387" x="0" y="0" width="8" height="13" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.31 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_600_36387"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="8"/>
<feGaussianBlur stdDeviation="2.5"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
<feBlend mode="normal" in2="effect1_innerShadow_600_36387" result="effect2_innerShadow_600_36387"/>
</filter>
<radialGradient id="paint0_radial_600_36387" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(4 8) rotate(-90) scale(8)">
<stop offset="0.125" stop-color="#EAFAF7"/>
<stop offset="1" stop-color="#08E2A7"/>
</radialGradient>
<radialGradient id="paint1_radial_600_36387" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(4) rotate(90) scale(8)">
<stop stop-color="#2DAE8D"/>
<stop offset="1" stop-color="#ECF9F4"/>
</radialGradient>
</defs>
</svg>

    </div>
  </div>
  )
}

export default LiveSvg