import React from 'react'

function Arrow() {
  return (
    <div><svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.5032 9.41421L7.89663 18.0208L6.48242 16.6066L15.089 8H7.50324V6H18.5032V17H16.5032V9.41421Z" fill="white"/>
    </svg>
    </div>
  )
}

export default Arrow